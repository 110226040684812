<template>
  <v-layout v-if="_.size(detail) > 0" row wrap>
    <v-flex xs12 lg12 md12>
      <v-card>
        <titleCard title="Atributos" subtitle="Listado atributos asociados a este producto"> </titleCard>
        <v-divider></v-divider>
        <v-data-table
          :items="attributesProduct"
          class="elevation-1"
          no-data-text="No hay atributos ingresados"
          :headers="[
            { text: 'Grupo Atributo', align: 'left', sortable: false },
            { text: 'Defecto', align: 'left', sortable: false },
            { text: 'Estado', align: 'left', sortable: false },
            { text: 'Acciones', align: 'left', sortable: false }
          ]"
          hide-actions
        >
          <template v-slot:items="props">
            <td v-html="props.item.attribute.name"></td>
            <td v-html="props.item.selected.name"></td>
            <td>
              <v-chip
                v-if="props.item.status"
                color="success"
                text-color="white"
                label
                small
                @click="changeStatusAttribute(props.item.id, props.item.status)"
              >
                Activo
              </v-chip>
              <v-chip
                v-else
                color="error"
                text-color="white"
                label
                small
                @click="changeStatusAttribute(props.item.id, props.item.status)"
              >
                Inactivo
              </v-chip>
            </td>
            <td>
              <v-icon small @click="editAttribute(props.item)">
                edit
              </v-icon>
              <v-icon small @click="deleteAttribute(props.item.id)">
                delete
              </v-icon>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
    <v-btn fixed dark fab bottom right color="accent" @click="openAttribute">
      <v-icon> add </v-icon>
    </v-btn>
    <v-dialog v-model="dialogAttribute" persistent max-width="300px">
      <v-card>
        <titleDialog :title="'Añadir Atributo'" close :close-action="() => (dialogAttribute = false)"></titleDialog>
        <v-divider></v-divider>
        <v-card-text>
          <v-select
            v-model="attributeProduct.attribute"
            :items="attributes"
            item-text="name"
            return-object
            label="Grupo de atributos"
          ></v-select>
          <v-select
            v-model="attributeProduct.selected"
            :items="attributeProduct.attribute.atributos"
            item-text="name"
            return-object
            label="Atributo por defecto"
          ></v-select>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="back" flat @click.native="dialogAttribute = false">Volver</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" flat @click.native="saveAttribute">Listo</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { GET_ATRIBUTES, GET_PRODUCT } from '../../config'
import titleCard from '../useful/titleCard.vue'
import titleDialog from '../useful/titleDialog.vue'

export default {
  name: 'ProductAttributes',
  components: { titleCard, titleDialog },
  props: ['product', 'getProduct'],
  data: () => ({
    attributeProduct: {
      attribute: {},
      selected: {},
      id: 0
    },
    attributesProduct: [],
    attributes: [],
    dialogAttribute: false,
    loadingBtn: false,
    loading: false,
    active: true,
    stock: true,
    scheduleRetirement: 0,
    detail: {},
    states: [
      { name: 'Florida', abbr: 'FL', id: 1 },
      { name: 'Georgia', abbr: 'GA', id: 2 },
      { name: 'Nebraska', abbr: 'NE', id: 3 },
      { name: 'California', abbr: 'CA', id: 4 },
      { name: 'New York', abbr: 'NY', id: 5 }
    ]
  }),
  watch: {
    product() {
      this.detail = this.product
      this.setAttributes()
    }
  },
  mounted() {
    this.detail = this.product
    this.getAttributes()
  },
  methods: {
    async getAttributes() {
      try {
        const res = await this.$http.get(GET_ATRIBUTES)
        this.attributes = res.data
        this.setAttributes()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async saveAttribute() {
      const { attributeProduct } = this
      try {
        const res = await this.$http.post(`${GET_PRODUCT}/${this.product.id}/attribute`, {
          attributeID: attributeProduct.attribute.id,
          default: attributeProduct.selected.id,
          edit: attributeProduct.id
        })
        if (!res.data.error) {
          this.getProduct()
          this.dialogAttribute = false
        }
        this.$store.dispatch('changeSnack', {
          active: true,
          text: res.data.msj
        })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async deleteAttribute(id) {
      try {
        const res = await this.$http.delete(`${GET_PRODUCT}/${this.product.id}/attribute/${id}`)
        if (!res.data.error) {
          this.getProduct()
          this.dialogAttribute = false
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async changeStatusAttribute(id, status) {
      try {
        await this.$http.put(`${GET_PRODUCT}/${this.product.id}/attribute/${id}/status`, {
          status
        })
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Actualizado'
        })
        this.getProduct()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    editAttribute(item) {
      this.attributeProduct = item
      this.openAttribute()
    },
    openAttribute() {
      this.dialogAttribute = true
    },
    setAttributes() {
      if (this._.size(this.detail) > 0) {
        const attributes = []
        this.detail.attributes.forEach(element => {
          const result = this._.find(this.attributes, ['id', element.idgrupo_atributos])
          const selected = this._.find(result.atributos, ['id', element.defecto])
          attributes.push({
            attribute: result,
            selected,
            status: element.status,
            id: element.id
          })
        })
        this.attributesProduct = attributes
      }
    }
  }
}
</script>

<style></style>
